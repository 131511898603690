<template>
	<main class="main">
    <div class="container">
      <Crumbs :data="crumbs"></Crumbs>
    </div>
		<section class="main__box">
			<div class="container">
				<h1 class="main__title">
					Материалы
				</h1>
				<GridVideo></GridVideo>
			</div>
			<!--<SliderVideo></SliderVideo>-->
		</section>

		<div class="bg bg--gray">
			<section class="container">
				<Documents :data="documents"></Documents>
			</section>
		</div>

		<section class="main__box" v-if="blocks">
			<div class="container">
				<Info :data="blocks"></Info>
			</div>
		</section>
	</main>
</template>

<script>
import GridVideo from '@/components/GridVideo.vue'
//import SliderVideo from '@/components/SliderVideo.vue'
import Documents from '@/components/Documents.vue'
import Info from '@/components/Info.vue'
import Crumbs from '@/components/Crumbs'

export default {
	name: 'Materials',

	components: {
		GridVideo,
		Documents,
		Info,
		Crumbs,
	},

	computed: {
		blocks() {
			const blocks = this.$store.getters.BLOCKS
			return blocks[this.$options.name]
		},

		documents() {
			return {
				title: 'Документы',
				type: 'basic',
				crumbs: null,
			}
		},
	},
	mounted() {
		let self = this
		self.$nextTick(() => {
			self.$store.dispatch('GET_BLOCKS', {
				page: self.$route.fullPath,
				block: self.$options.name,
			})
		})

    this.crumbs = [
      {
        link: 'HolderHome',
        text: 'Главная',
      },
      {
        link: null,
        text: 'Материалы',
      },
    ]
	},
}
</script>
